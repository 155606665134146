import styled, { css } from 'styled-components';

import SectionWrapper from '../SectionWrapper';
import { queries } from "../../../breakpoints"

export const Wrapper = styled(SectionWrapper)`
    --repeat: auto-fit;

    .columns-4 {
        --card-width: 200px;

        @media (${queries.min_break_lg}) {
            --repeat: 4;
        }
    }

    .columns-3 {
        --card-width: 250px;

        @media (${queries.min_break_lg}) {
            --repeat: 3;
        }
    }
    
    .columns-2 {
        --card-width: 300px;
        max-width:960px;
        margin:0 auto;
        
        @media (${queries.min_break_md}) {
            --repeat: 2;
        }
    }

    // Hide this Wrapper if we need to show the slider on mobile
    // if its 2 cards then hide under break_md
    ${props => (props.isSliderOnMobile & props.cardsPerRow === 2) &&
        css`
        @media (${queries.max_break_md}) {
            display: none;
        }
        `
    };
    // if its 3 or more cards then hide under break_lg
    ${props => (props.isSliderOnMobile & props.cardsPerRow > 2) &&
        css`
        @media (${queries.max_break_lg}) {
            display: none;
        }
        `
    };
  
    ${props => props.buttonPosition === 'center' &&
        css`
          [class*="Buttonstyles__StyledButton"]{
              display: flex;
              justify-content: center;
              margin: 0 auto;
              background-color: var(--color-primaryNew);
            
                &:hover{
                  background-color: var(--color-primary-darkerNew);
                }
            }
          h4, p{
            text-align: center;
          }
          [class*="CardsSectionstyles__Grid"]{
            max-width: 825px;
            gap: 25px;
            [class*="CustomCardstyles__StyledWrapper"]{
              max-width: 400px;
            }
          }
        `
    };

`;

export const Headline = styled.h2`
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
`;

export const Subheadline = styled.p`
    font-size: var(--fontSize-3);
    text-align: center;
    margin-bottom: 25px;
`;

export const Grid = styled.div`
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(var(--repeat, auto-fit), minmax(var(--card-width), 1fr));
  
    .MuiCard-root{
      border-radius: 14.108px;
      border: 1.176px solid #A4A3A3;
      background: #FFF;
      box-shadow: none;
    }
`;

export const StyledPageSwitcher = styled.div`   
    display: flex; 
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
`;

export const PageNumber = styled.p`
  color: #6B6B83;
  font-size: var(--fontSize-1);
  margin: 0 7px;
`;

export const PageSwitcherButton = styled.button` 
    width: 45px; 
    height:45px;
    border-radius: 100px;
    padding: 0;
    border: none;
    background-color:#AEAEAE60;
    display:flex;
    justify-content:center;
    align-items:center;
    margin: 0 2px;
    svg{
        color: #545470;
    }
    ${props => props.disabled && 
        css`
            opacity: .25;
        `
    }
`;

export const SliderWrapper = styled.div`
    ${props => props.backgroundColor && css`background-color: ${props.backgroundColor}`};
    ${props => props.sectionPadding ? 
        props.sectionPadding
        : css`
            padding: var(--section-verticalPadding) 0;
        `
    };

    // Default the slider to hidden unless isSliderOnMobile === true
    ${props => props.isSliderOnMobile ? 
        css`
            display:block;
        ` : 
        css`
            display:none;
        `
    };

    // Show the slider on mobile
    // if its 2 cards then hide over break_md
    ${props => (props.isSliderOnMobile & props.cardsPerRow === 2) &&
        css`
        @media (${queries.min_break_md}) {
            display: none;
        }
        `
    };
    // if its more than 2 cards then hide over break_lg
    ${props => (props.isSliderOnMobile & props.cardsPerRow > 2) &&
        css`
        @media (${queries.min_break_lg}) {
            display: none;
        }
        `
    };

`;

