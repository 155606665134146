import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Grid, Headline, PageNumber, PageSwitcherButton, SliderWrapper, StyledPageSwitcher, Subheadline, Wrapper } from "./GHCardsSection.styles"
import React, { useState } from 'react';

import CardCarouselSection from "../CardCarouselSection"
import CustomCard from '../../global/CustomCard';
import PropTypes from 'prop-types';
import { chunk } from "lodash";

const GHCardsSection = (props) => {
  const [ page, setPage ] = useState(0);

  // Change the page
  const switchPages = (value) => {
    setPage(page + value)
  }

  const renderCards = () => {
    // Chunk the cards based on the
    let cardChunks = chunk(props.cards, props.cardsPerRow);
    return(
      <>
      <Grid className={`columns-${props.cardsPerRow}`}>
        { cardChunks[page].map((card,i) => {
          if(i < props.cardsPerRow){
            return(
              <CustomCard
                  key={i}
                  title={card.title}
                  description={card.description}
                  link={card.link}
                  imageSrc={card.imageSrc}
                  linkText={card.linkText}
                  buttonType={props.buttonType}
                />
            )}
            return <></>
          }
        )}
      </Grid>
      <PageSwitcher numberOfPages={cardChunks.length}/>
      </>
    )
  }


  const PageSwitcher = (props) => {
    if(props.numberOfPages > 1){
      return (
        <StyledPageSwitcher>
          <PageNumber>{page + 1} / {props.numberOfPages}</PageNumber>
          <PageSwitcherButton
            // If the first page, disable the previous button
            disabled={page === 0}
            onClick={() => switchPages(-1)}
          >
            <FaChevronLeft size={20}/>
          </PageSwitcherButton>
          <PageSwitcherButton
            // If the last page, disable the next button
            disabled={page === props.numberOfPages-1}
            onClick={() => switchPages(1)}
          >
            <FaChevronRight size={20}/>
          </PageSwitcherButton>
        </StyledPageSwitcher>
      )
    }
    return <></>
  }

  return(
    <>
    <Wrapper className={' gh-cards-section'} buttonPosition={props.buttonPosition} backgroundColor={props.backgroundColor} sectionPadding={props.sectionPadding} isSliderOnMobile={props.isSliderOnMobile} cardsPerRow={props.cardsPerRow} >
      {props.headline && <Headline>{props.headline}</Headline>}
      {props.subheadline && <Subheadline>{props.subheadline}</Subheadline>}
      {!props.chunkCards &&
      <Grid className={`columns-${props.cardsPerRow}`}>
        { props.cards.map((card,i) => (
            <CustomCard
                className={props.buttonPosition ? 'gh-center-pos' : ''}
                key={i}
                title={card.title}
                description={card.description}
                link={card.link}
                imageSrc={card.imageSrc}
                buttonType={props.buttonType}
                linkText={card.linkText}
              />
          ))
        }
      </Grid>
      }
      {/* If props.chunkCards then render the cards in chunks based on the cardsPerRow and add pagination to it */}
      {props.chunkCards && renderCards()}
    </Wrapper>

    <SliderWrapper isSliderOnMobile={props.isSliderOnMobile} sectionPadding={props.sectionPadding} backgroundColor={props.backgroundColor} cardsPerRow={props.cardsPerRow} >
        {props.headline && <Headline>{props.headline}</Headline>}
        <CardCarouselSection cards={props.cards}/>
    </SliderWrapper>
    </>
  )
};




GHCardsSection.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    imageSrc: PropTypes.string
  })),
  cardsPerRow: PropTypes.number,
  backgroundColor: PropTypes.string,
  isSliderOnMobile: PropTypes.bool,
  chunkCards: PropTypes.bool,
  buttonPosition: PropTypes.string,
};

GHCardsSection.defaultProps = {
  headline: null,
  subheadline: null,
  cards: [],
  cardsPerRow: 2,
  backgroundColor: "white",
  isSliderOnMobile: false,
  chunkCards: false,
  buttonPosition: null,
};

export default GHCardsSection;
