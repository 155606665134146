import { Author, Headline, Image, StyledContentWrapper, Subheadline, TextContent } from "./GHQuoteSection.styles"

import Button from '../../global/Button'
import PropTypes from 'prop-types';
import React from 'react';
import SectionWrapper from '../SectionWrapper'

const GHQuoteSection = (props) => (
  <SectionWrapper backgroundColor={props.display === 'default' ? props.backgroundColor : ''}>
    <StyledContentWrapper display={props.display} backgroundColor={props.display === 'boxed' ? props.backgroundColor : null} layout={props.layout} imageAlignment={props.imageAlignment}>
      <Image src={'/images/pages/catering/catering_quotes_sign.svg'}/>
      <TextContent>
      {(props.layout === "horizontal" && props.imgQuote === true) && <img style={{marginBottom: '20px'}} className="quote-section__quote-image" src={"https://res.cloudinary.com/grubhub-marketing/image/upload/v1627673851/Grubhub%20Corporate%20Website/2021/Navigation/QuotationMark.svg"} alt={`Quote`}/>}
        <Headline>{props.quote}</Headline>
        <Subheadline>
          <Author>{props.author}{props.author ? ", " : ""}</Author><span className="quote-section__positon">{props.position}</span>
        </Subheadline>
        {props.ctaLink  ? <Button to="/our-clients/" type="secondary">{props.ctaText}</Button> : ""}
      </TextContent>
      <Image src={props.imageSrc} alt={props.alt}/>
    </StyledContentWrapper>
  </SectionWrapper>
);

GHQuoteSection.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  position: PropTypes.string,
  imgQuote: PropTypes.bool,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  imageSrc: PropTypes.string,
  alt: PropTypes.string,
  backgroundColor: PropTypes.string,
  layout: PropTypes.oneOf(["vertical", "horizontal"]),
  imageAlignment: PropTypes.oneOf(["left", "right"]),
  display: PropTypes.string,
};

GHQuoteSection.defaultProps = {
  quote: 'Here is where the quote goes...',
  author: "",
  position: "",
  ctaLink: "",
  imgQuote: true,
  ctaText: "Get started",
  imageSrc: "https://via.placeholder.com/250x100",
  alt: "",
  backgroundColor: "white",
  layout: "vertical",
  imageAlignment: "left",
  display: "default"
};

export default GHQuoteSection;
