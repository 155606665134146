import CardsSection from "../components/sections/CardsSection/CardsSection"
import CtaSection from "../components/sections/CtaSection"
import FaqAccordionSection from "../components/sections/FaqAccordionSection"
import GHGeneralHeader from "../components/global/GHGeneralHeader/GHGeneralHeader"
import GHLayout from "../components/global/GHLayout"
import QuoteSection from "../components/sections/QuoteSection/QuoteSection"
import React from "react"
import ValuePropsSection from "../components/sections/ValuePropsSection"
import GHValuePropsCustomBox from "../components/sections/GHValuePropsCustomBox/GHValuePropsCustomBox";
import GHQuoteSection from "../components/sections/GHQuoteSection/GHQuoteSection";
import GHCardsSection from "../components/sections/GHCardsSection/GHCardsSection";
import GHCtaSection from "../components/sections/GHCtaSection/GHCtaSection";
import GHFaqAccordionSection from "../components/sections/GHFaqAccordionSection/GHFaqAccordionSection";

const Catering = ({ location }) => {

  const imageSizesValueProps = {
    maxWidth: "321.65px",
    maxHeight: "208px"
  }

  const valueProps = [
    {
      headline: "Office events and parties",
      subheadline: "Nothing says ‘Happy birthday!’ or ‘Great job!’ like delicious food.",
      imageSrc: "/images/pages/catering/catering_office_events_and_parties.png",
      imageSize: imageSizesValueProps,
    },
    {
      headline: "Weekly team meals",
      subheadline: "Foster collaboration and team building by gathering everyone around the table.",
      imageSrc: "/images/pages/catering/catering_weekly_team_meals.png",
      imageSize: imageSizesValueProps,
    },
    {
      headline: "Lunch and learns",
      subheadline: "Make meetings more delicious and keep your team engaged.",
      imageSrc: "/images/pages/catering/catering_lunch_and_learns.png",
      imageSize: imageSizesValueProps,
    },
  ]


  const valuePropsMeals = [
    {
      headline: "Catering made easy",
      subheadline: "Hosting a lunch and learn? Feeding a board meeting? Let us handle it.",
      imageSrc: "/images/pages/catering/catering_made_easy.svg",
      imageSize: imageSizesValueProps,
    },
    {
      headline: "Feed any occasion, big or small",
      subheadline: "Preorder meals in advance or order on-demand for same-day meetings.",
      imageSrc: "/images/pages/catering/catering_cater_to_every_taste.svg",
      imageSize: imageSizesValueProps,
    },
    {
      headline: "Optimized search features",
      subheadline: "Use our search filter to find restaurants that will individually box everyone’s meal.",
      imageSrc: "/images/pages/catering/catering_optimized_search.svg",
      imageSize: imageSizesValueProps,
    },
    {
      headline: "Cost-effective",
      subheadline: "Corporate catering is an easy and affordable way to feed big teams in the office.",
      imageSrc: "/images/pages/catering/catering_cost_effective.svg",
      imageSize: imageSizesValueProps,
    },
    {
      headline: "Built-in controls",
      subheadline: "Keep costs in check by only paying for what your employees spend.",
      imageSrc: "/images/pages/catering/catering_built_in_controls.svg",
      imageSize: imageSizesValueProps,
    },
    {
      headline: "On-site staffing",
      subheadline: "Let Grubhub Corporate design, build, and manage all aspects of your food ordering program, including on-site staffing.",
      imageSrc: "/images/pages/catering/catering_feed_occasion.svg",
      imageSize: imageSizesValueProps,
    },
  ]

  const valuePropsOrdering = [
    {
      headline: "Visit the catering order page in your account portal",
      imageSrc: "/images/pages/catering/catering_number_one.svg",
      imageSize: imageSizesValueProps,
    },
    {
      headline: "Feed any occasion, big or small",
      imageSrc: "/images/pages/catering/catering_number_two.svg",
      imageSize: imageSizesValueProps,
    },
    {
      headline: "Add your food, enter your address, and pick your delivery time",
      imageSrc: "/images/pages/catering/catering_number_three.svg",
      imageSize: imageSizesValueProps,
    },
    {
      headline: "Enjoy!",
      imageSrc: "/images/pages/catering/catering_number_four.svg",
      imageSize: imageSizesValueProps,
    },
  ]

  const cards = [
    {
      title: "Individual Meals",
      description: "Employees can order meals to the office, home or wherever they’re working—you set the budget and control spend.",
      link: "/individual-meals/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626794994/Grubhub%20Corporate%20Website/2021/Photography%20Elements/1-WaysToOrder-Homepage/EXPORTS-Photography-WaysToOrder-Homepage-IndividualMeals.jpg",
      linkText: "Learn more"
    },
    {
      title: "Group Ordering",
      description: "Send out restaurant choices and let your team order what they want. Or, let employees add food to a shared cart. Any way you order, meals are delivered together and everyone gets something they love.",
      link: "/group-orders/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797393/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-GroupOrdering.jpg",
      linkText: "Learn more"
    }
  ]

  const faqs = [
    {
      header: "What do you serve at a lunch meeting?",
      description: `<span>Grubhub thinks business lunches should serve delicious, easy to eat food like individually-boxed sushi or salads. With a <a href="/">Grubhub Corporate Account</a> you can cater a business lunch that satisfies every taste. From salad to tacos, we've got your team covered with a variety of options from the nation’s largest network of local restaurant partners.</span>`
    },
    {
      header: "Does Grubhub deliver catering?",
      description: `<span>Grubhub delivers individually-boxed catering meals. A <a href="/">Grubhub corporate account</a> makes it easy to <a href="/catering/">cater to every taste</a>. From platters to individually boxed meals, our network of caterers can prepare your favorites for a business lunch delivered by <a href="https://www.grubhub.com">Grubhub</a>.</span>`
    }
  ];

  return (
    <GHLayout
      location={location}
      title={"Office Catering | Catering | Grubhub Corporate"}
      description={"Want to make catering for your next team lunch safe and easy? Learn about Grubhub Corporate Account catering options including individually boxed meals."}
      className="catering-page"
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "ways to order",
        pageName: "catering",
        brand: "grubhub"
      }}
      faqSeoList={faqs}
    >
      <GHGeneralHeader
          headline="Catering"
          preheader="Ways to order"
          preheaderColor={"#242E30"}
          textColor="#242E30"
          background="#FFFFFF"
          backgroundForm="#FFFFFF"
          formStyle={{ background:"#FFFFFF", borderRadius: "20.695px", border: "0.69px solid #A4A3A3", padding: "32px 26px 39px 26px" }}
          formID={1786}
          backgroundColorForm='#FFF'
          formHeading="Sign up to learn more"
          type="full-image"//"half-image"
          alt="Grubhub delivery driver walking carrying two orange food bags."
          imageSrc="/images/pages/catering/catering_header_image.png"
          //imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1652368058/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Catering/Header-Catering-Square-NoCurve-V2.png"
      />
      <GHValuePropsCustomBox
          headline="Cater to every occasion"
          textColor={"#ffffff"}
          backgroundColor={"#ff8800"}
          valueProps={valueProps}
          layout="vertical"
          theme="catering"
          maxWidth="1024px"
          bottomGraphic={true}
          bottomGraphicNegativeColor={"#F5F3F1"}
          style={{ paddingBottom: "100px" }}
      />
      <GHValuePropsCustomBox
          headline="Delight your team with delicious meals"
          textColor={"#242E30"}
          backgroundColor={"#F5F3F1"}
          valueProps={valuePropsMeals}
          layout="vertical"
          theme="catering"
          maxWidth="1024px"
      />
      <GHValuePropsCustomBox
          headline="Ordering office catering is easy"
          textColor={"#242E30"}
          backgroundColor={"#F2A6B0"}
          valueProps={valuePropsOrdering}
          layout="vertical"
          theme="catering"
          maxWidth="1024px"
          maxPropWidth="212px"
          propsPerRow={4}
          spacingBottom={'240px'}
      />
      <GHQuoteSection
          quote='Grubhub makes corporate catering so easy! The restaurant selections are fabulous and we can usually find any kind of cuisine to accommodate any request or diet restriction.'
          author="Carol Keesee"
          position="Planning Operations Manager at GSD&M"
          imageSrc="/images/pages/catering/catering_GSD&M Logo.png"
          alt=""
          backgroundColor="#FF8000"//"rgba(16,143,87,0.05)"
          display="boxed"
      />

      <GHCardsSection
          headline="More ways to order"
          cards={cards}
          /*isSliderOnMobile*/
          buttonType="primary"
          buttonPosition="center"
      />
      <GHCtaSection
          buttonType="secondary"
          ctaText="Get started"
          to="/contact-us/"
          headline="Discover the industry leader in flexible meal benefits"
          backgroundColor="#C1DADE"
          maxWidth="548px"
          topSpacingMobile={"0"}
          bottomSpacingMobile={"0"}

          graphicTopSrc={'/images/pages/catering/catering_caesar_salad.png'}
          graphicBottomSrc={'/images/pages/catering/catering_club_sandwich.png'}
      />
      <GHFaqAccordionSection faqs={faqs} backgroundColor="white"/>
      {/*<CardsSection headline="More ways to order" cards={cards} isSliderOnMobile/>
      <CtaSection ctaText="Get started" to="/contact-us/" headline="Discover the industry leader in flexible meal benefits" backgroundColor="#F9F9F9"/>
      <FaqAccordionSection faqs={faqs} backgroundColor="white"/>*/}
    </GHLayout>
  )
}

export default Catering
