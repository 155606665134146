import styled, { css } from 'styled-components';

import { queries } from '../../../breakpoints'

export const Image = styled.img`
    max-width:250px;
    width:90%;
    margin: 0 auto 25px;
`;

export const TextContent = styled.div``;

export const StyledContentWrapper = styled.div`
    margin-top: -300px;


  @media (${queries.max_break_lg}) {
    margin-bottom: -50px;
  }
  
  
    img{
      width: 131px;
      height: 51px;
    }
    ${props => props.layout === "vertical" ? 
        // Vertical layout styles
        css`
            text-align: center;
        ` :

        // Horizontal layout styles
        css`
            display:flex;
            flex-flow:column;
            justify-items: center;
            grid-template-areas: "image text";
            align-items: center;
            gap: 0 25px;
            ${Image} {
                flex: 1;
                grid-area: image;
                @media(${queries.max_break_lg}){
                    max-width:250px;
                }
                @media(${queries.min_break_lg}){
                    max-width:350px;
                }
            }
            ${TextContent}{
                flex: 1;
                text-align: left;
                grid-area: text;
                @media(${queries.max_break_lg}){
                    width: 100%;
                }
            }
            @media(${queries.min_break_lg}){
                display: grid;
                grid-template-columns: 50% 50%;
            }

        `
    }
    ${props => props.display === "boxed" &&
            css`
              background-color: ${props.backgroundColor};
              border-radius: 26px;
              padding: 34px 96px;
              @media (${queries.max_break_md}) {
                padding: 34px 30px;
              }
        `
    }
    
    // Align image on right side grid area
    ${props => props.imageAlignment === "right" &&
        css`
            grid-template-areas: "text image";
        `
    } 
}
`;


export const Headline = styled.h2`
    font-family: var(--jetSansDigital-fontFamily);
    font-weight: bold;
    font-size: clamp(var(--fontSize-4), 5vw, var(--fontSize-6));
    color: #ffffff;
      @media (${queries.max_break_lg}) {
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }
`;

export const Subheadline = styled.p`
    font-family: var(--jetSansDigital-fontFamily);
    margin: 40px 0;
    font-size: clamp(var(--fontSize-0), 3vw, var(--fontSize-2));
    color: #ffffff;
    display: flex;
    
      @media (${queries.max_break_lg}) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 34px auto 40px;
        max-width: 164px;
        span:nth-child(1) {
            text-align: center;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 2px;
          }
          span:nth-child(2) {
              text-align: center;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal; 
          }
    }
`;

export const Author = styled.span`
    font-family: var(--jetSansDigital-fontFamily);
    font-weight: bold;
`;
